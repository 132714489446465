import axios from "axios";
import store from "@/store";

const setAxios = ({ headers, baseURL = process.env.VUE_APP_SESSION_API }) => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      app: "autologin",
      withCredentials: true,
      ...headers,
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const { data } = response;
      if (data.hasOwnProperty("validSession") && !data.validSession) {
        store.dispatch("user/logoutClient");
        window.location.href = "/sesionwrong";
      }
      return response;
    },
    (error) => {
      console.error("Response error:", error);
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default setAxios;
