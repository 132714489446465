<script>
import axiosTOTP from "../../api/axiosTOTP";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    art: () => import("../art/art.vue"),
  },

  data() {
    return {
      user: {
        secret: "",
        secretQR: "",
      },
      TOTPCode: {
        TOTP_digit_1: "",
        TOTP_digit_2: "",
        TOTP_digit_3: "",
        TOTP_digit_4: "",
        TOTP_digit_5: "",
        TOTP_digit_6: "",
      },
      is2FAEnabled: null,
      is2FARequired: false,
    };
  },

  computed: {
    ...mapGetters("user", { getIsRequired2FA: "getIsRequired2FA", getToken: "getToken", getUser2FASecret: "getUser2FASecret" }),
  },

  methods: {
    ...mapMutations("user", { setIs2FALogged: "setIs2FALogged" }),
    ...mapActions("user", { get2FAOptionalAreas: "get2FAOptionalAreas" }),

    onKeyUp(event) {
      const input = event.target;
      const inputRef = input.name;
      const value = event.key;
      const numericValue = parseInt(event.key);

      if (value === "Backspace") {
        this.focusPrevInput(inputRef);
      }

      if (isNaN(numericValue)) {
        this.cleanInput(inputRef);
      } else {
        this.TOTPCode[inputRef] = numericValue;
        this.focusNextInput(inputRef);
      }
    },

    focusPrevInput(inputRef) {
      const prevInput = document.querySelector(`.${inputRef}`).previousSibling;
      if (prevInput) {
        prevInput.focus();
      }
    },

    cleanInput(inputRef) {
      document.querySelector(`.${inputRef}`).value = "";
    },

    focusNextInput(inputRef) {
      const nextInput = document.querySelector(`.${inputRef}`).nextSibling;

      if (!nextInput) {
        this.verifyTOTPCode();
      } else {
        nextInput.focus();
      }
    },

    focusFirstInput() {
      document.querySelector(".TOTP_digit_1").focus();
    },

    async verifyTOTPCode() {
      const token = this.getInputTOTPCode();
      const { _id } = this.$store.state.user.profile;

      const { data } = await axiosTOTP(this.TOTPHeaders).post("/auth/verifi2FA", { token, userSecret: this.user.secret, _id, isFirstTime: !this.is2FAEnabled });
      const { isValidToken } = data;

      if (isValidToken) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, this.getToken);
        this.setIs2FALogged(true);
        await this.get2FAOptionalAreas();
        this.$router.push({ path: "/apps" });
      } else {
        this.$toast.error("Token invalido");
      }

      this.cleanTOTPCode();
      this.focusFirstInput();
    },

    getInputTOTPCode() {
      let TOTPCode = "";
      for (const item in this.TOTPCode) {
        TOTPCode += this.TOTPCode[item];
      }

      return parseInt(TOTPCode);
    },

    cleanTOTPCode() {
      for (const item in this.TOTPCode) {
        this.TOTPCode[item] = "";
      }
    },
    skip2FA() {
      localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, this.getToken);
      this.$router.push({ name: "apps" });
    },
  },

  async mounted() {
    this.is2FAEnabled = JSON.parse(localStorage.getItem("is2FAEnabled"));
    this.TOTPHeaders = { headers: { [process.env.VUE_APP_TOKEN_NAME]: this.getToken } };
    const userHasSecret = this.getUser2FASecret;

    if (!userHasSecret) {
      const { data } = await axiosTOTP(this.TOTPHeaders).get("/auth/get2FASecret");
      const { success, userSecret, userSecretQR } = data;

      if (success) {
        this.user.secret = userSecret;
        this.user.secretQR = userSecretQR;
      }
    } else {
      this.user.secret = userHasSecret;
    }
    this.focusFirstInput();
    this.is2FARequired = this.getIsRequired2FA;

    this.$nextTick(() => {
      setTimeout(() => {
        this.focusFirstInput();
      }, 1000);
    });
  },
};
</script>

<template>
  <div class="login2fa fade-in">
    <div class="login2fa__body">
      <div class="login2fa__container">
        <div class="login2fa__inner">
          <div class="login2fa__containerDescription">
            <h2 dcolor class="title_authenticator">Autenticación de 2 Factores</h2>
          </div>
          <div class="login2fa__formContainer">
            <div class="formContainer__qr" v-if="!this.is2FAEnabled">
              <p class="login2fa__stepDesc"><span class="list__order">1.</span> Escanea el siguiente código con la aplicación Google Authenticator.</p>
              <img :src="this.user.secretQR" />
            </div>
            <div v-bind:class="this.is2FAEnabled ? 'login2fa__token--enabled' : 'login2fa__token--disabled'">
              <p class="login2fa__stepDesc"><span v-if="!this.is2FAEnabled" class="list__order">2.</span> Ingresa el código de 6 dígitos generado dentro de la aplicación.</p>

              <div class="login2fa__inputGroup">
                <input v-for="(item, key) in TOTPCode" type="text" inputmode="numeric" @keyup="onKeyUp($event)"
                  :ref="`${key}`" :name="key" :class="key" maxlength="1" v-model="TOTPCode[key]" autocomplete="off" />
              </div>

              <a href="#" class="input__skip" v-if="!this.is2FARequired" @click="skip2FA">Saltar este paso</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$description-color: $tundora;
$lipstick: $lipstick;

.title_authenticator {
  text-transform: none;
  font-family: $newFont_regular;
  font-size: 15px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.input__skip {
  font-family: $newFont_regular;
}

.login2fa {
  align-items: center;
  justify-content: center;
  width: 100%;
  
  &__body {
  display: flex;
  justify-content: center;
  align-items: center;
}

  &__container {
    position: relative;
    padding-bottom: 15%;
    max-height: 100%;
    text-align: center;
    border-radius: $mradius/2;
    overflow-y: auto;
    width: 90%;
  }

  &__inner {
    position: relative;
    font-size: 15px;
    z-index: 2;
  }

  &__containerDescription {
    margin: 25px 0 0px;
    font-family: $newFont_regular;
    font-size: 19px;
  }

  &__token--enabled {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__stepDesc {
    font-family: $newFont-regular;
    font-size: 15px;
    margin-bottom: 16px;

    .list__order {
      font-weight: bold;
      font-family: $newFont-regular;
    }
  }

  &__inputGroup {
    font-family: $newFont-regular;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0 20px;

    [class*="TOTP_digit_"] {
      width: 40px;
      height: 40px;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      border: 0;
      border-radius: 8px;
      background: $porcelain;
      color: $lipstick;
      transition: all ease-in-out 50ms;

      &:focus {
        border: 2px solid $lipstick;
      }
    }
  }


  @include tabletWidth() {
    &__container {
      padding-bottom: 0px;
    }
  }
}
</style>
